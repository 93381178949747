import React, { useState } from 'react';

const AddCurlyAnimalsForm = props => {
	const [link, setLink] = useState('');
	const handleSubmit = async e => {
		e.preventDefault();
		await props.firebase.db.collection('curly-animals').add({
			link: link,
			created: new Date()
		});
		setLink('');
	};
	return (
		<form onSubmit={handleSubmit}>
			<label htmlFor="link">Link to curly animal image:</label>
			<input type="text" value={link} onChange={e => setLink(e.target.value)} />
			<input type="submit" value="Add" />
		</form>
	);
};

export default AddCurlyAnimalsForm;
