import React from 'react';
import { Switch, Route } from 'react-router-dom';
import './App.scss';
import CurlyAnimals from './pages/Home/CurlyAnimals';
import Footer from './components/Footer';
import Admin from './pages/Admin/Admin';
import Bibliography from './pages/Bibliography/Bibliography';

function App() {
	return (
		<div className="App">
			<Switch>
				<Route exact path="/" component={CurlyAnimals} />
				<Route exact path="/admin" component={Admin} />
				<Route exact path="/bibliography" component={Bibliography} />
			</Switch>
			<Footer />
		</div>
	);
}

export default App;
