import React from 'react';
import { Link } from 'react-router-dom';
import { FirebaseContext } from '../../Firebase';
import AddCurlyAnimals from './components/AddCurlyAnimals';

const Admin = () => {
	return (
		<FirebaseContext.Consumer>
			{firebase => (
				<>
					<h5>
						<Link to="/">
							<b>Back to Curly Animals</b>
						</Link>
					</h5>
					<AddCurlyAnimals firebase={firebase} />
				</>
			)}
		</FirebaseContext.Consumer>
	);
};

export default Admin;
