import React from 'react';
import CurlyAnimalImages from './components/CurlyAnimalImages';
import { FirebaseContext } from '../../Firebase';



const CurlyAnimals = props => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<h1 style={{ fontSize: '14pt' }}>Curly Animals</h1>
			<FirebaseContext.Consumer>
				{firebase => (
					<CurlyAnimalImages firebase={firebase} />
				)}
			</FirebaseContext.Consumer>
		</div>
	);
};

export default CurlyAnimals;
