import React from 'react';

const ListItem = props => {
	return (
		<li>
			<a href={props.link}>{props.link}</a>
		</li>
	);
};

export default ListItem;
