import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
	return (
		<div id="footer">
			<ul style={{ margin: 'none' }}>
				<li>
					<Link to="/bibliography">bibliography</Link>
				</li>
				<li>
					<small>&copy; Copyright - <a href="https://www.calcourtney.net">Cal the Musical</a> {new Date().getFullYear()}</small>

				</li>
				<li>
					<Link to="/admin">login</Link>
				</li>
			</ul>
		</div>
	);
};

export default Footer;
