import React, { useEffect, useState } from 'react';
import ListItem from './ListItem';

const BibList = props => {
	const db = props.firebase.db;
	const [itemArray, setItemArray] = useState([]);
	useEffect(() => {
		db.collection('curly-animals').onSnapshot(snap => {
			const links = [];
			snap.forEach(doc => {
				links.push(doc.data().link);
			});
			setItemArray(links);
		});
		return () => {
			setItemArray([]);
		};
	}, [db]);

	return (
		<>
			{itemArray.map((item, i) => (
				<ListItem key={i} link={item} i={i} />
			))}
		</>
	);
};

export default BibList;
