import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

const Login = props => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const handleSubmit = e => {
		e.preventDefault();
		const user = props.firebase.login(email, password).then(res => {
			if (res) {
				props.setUser(res);
				console.log(user);
			}
		});
	};
	return (
		<>
			<form onSubmit={handleSubmit}>
				<input
					type="email"
					value={email}
					onChange={e => setEmail(e.target.value)}
					placeholder="Email..."
				/>
				<input
					type="password"
					value={password}
					onChange={e => setPassword(e.target.value)}
					placeholder="Password..."
				/>
				<input type="submit" value="Login" />
			</form>
		</>
	);
};

export default withRouter(Login);
