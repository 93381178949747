import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';

const firebaseConfig = {
	apiKey: 'AIzaSyBaQolEIZ8w__nB-z7HcA8tKNjM3__kLLg',
	authDomain: 'calendar-the-musical.firebaseapp.com',
	databaseURL: 'https://calendar-the-musical.firebaseio.com',
	projectId: 'calendar-the-musical',
	storageBucket: 'calendar-the-musical.appspot.com',
	messagingSenderId: '184650345134',
	appId: '1:184650345134:web:d4fc1350e196432900db3f',
	measurementId: 'G-9JRRM7M2YR'
};

export default class Firebase {
	constructor() {
		app.initializeApp(firebaseConfig);
		app.analytics();
		this.auth = app.auth();
		this.db = app.firestore();
	}

	login = async (email, password) => {
		await this.auth.signInWithEmailAndPassword(email, password);
	};

	signout = () => {
		this.auth.signOut();
	};
}
