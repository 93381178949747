import React from 'react';

const TableRow = props => {
	return (
		<div className="col" style={{flex:'1'}}>
			<img
				style={{ width: '200px' }}
				src={props.link}
				alt={'curly animal ' + props.i}
			/>
		</div>
	);
};

export default TableRow;
