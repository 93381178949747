import React, { useState, useEffect } from 'react';

const CurlyAnimalImage = props => {
	const [animals, setAnimals] = useState([]);
	const db = props.firebase.db;
	useEffect(() => {
		db
			.collection('curly-animals')
			.orderBy('created', 'desc')
			.get()
			.then(snap => {
				const linkArray = snap.docs.map(doc => doc.data().link);
				setAnimals(linkArray);
			});
		return () => setAnimals([]);
	}, [db]);
	return (
		<>
			{animals.map((animal, i) => (
				<img
					key={i}
					src={animal}
					alt={'curly animal' + props.i}
					style={{ width: '100%', maxWidth: '600px', margin: '1.5rem auto' }}
				/>
			))}
		</>
	);
};

export default CurlyAnimalImage;
