import React from 'react';
import { Link } from 'react-router-dom';
import { FirebaseContext } from '../../Firebase';
import BibList from './components/BibList';

const Bibliography = () => {
	return (
		<>
			<h5>
				<Link to="/">
					<b>Back to Curly Animals</b>
				</Link>
			</h5>
			<ol>
				<FirebaseContext.Consumer>
					{firebase => <BibList firebase={firebase} />}
				</FirebaseContext.Consumer>
			</ol>
		</>
	);
};

export default Bibliography;
