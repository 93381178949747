import React, { useState, useEffect } from 'react';
import TableRow from './TableRow';

const CurlyTable = props => {
	const [links, setLinks] = useState([]);
	const db = props.firebase.db;
	useEffect(() => {
		db.collection('curly-animals').orderBy("created",'desc').onSnapshot(snap => {
			const linkArray = snap.docs.map(doc => doc.data().link);
			setLinks(linkArray);
		});
		return () => setLinks([]);
	}, [db]);

	return (
		<div className="container">
			<div
				className="row"
				style={{
					display: 'flex',
					width: '100%',
					flexWrap: 'wrap',
					alignItems: 'center'
				}}
			>
				{links.map((link, i) => {
					return <TableRow key={i} link={link} i={i} />;
				})}
			</div>
		</div>
	);
};

export default CurlyTable;
