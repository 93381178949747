import React, { useState, useEffect } from 'react';
import CurlyTable from './CurlyTable';
import AddCurlyAnimalsForm from './AddCurlyAnimalsForm';
import { withRouter } from 'react-router-dom';
import Login from './Login';

const AddCurlyAnimals = props => {
	// const [loggedIn, setLoggedIn] = useState(1);
	const [user, setUser] = useState(null);
	const auth = props.firebase.auth;
	useEffect(() => {
		auth.onAuthStateChanged(user => setUser(user));
		console.log('yo');
		return () => setUser(null);
	}, [auth]);
	return (
		<>
			{user ? (
				<>
					<button
						onClick={e => {
							props.firebase.signout();
						}}
					>
						Log Out
					</button>
					<AddCurlyAnimalsForm firebase={props.firebase} />
					<CurlyTable firebase={props.firebase} />
				</>
			) : (
				<Login firebase={props.firebase} setUser={setUser} />
			)}
		</>
	);
};

export default withRouter(AddCurlyAnimals);
